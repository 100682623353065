<template
    lang="html"
    name="Header"
>
    <header
        id="header"
        class="header"
    >
        <div class="header__wrapper">
            <div class="header__logo">
                <a href="/">
                    <span class="header__logo-link header__logo-img" />
                    <!-- <span class="header__logo-text">FreelanceWritingCenter.com</span> -->
                </a>
            </div>
            <div class="header__info">
                <sign-block />
            </div>
        </div>
    </header>
</template>
<script>
// components
import SignBlock from '@/components/header/SignBlock'
// directive
import ClickOutside from 'vue-click-outside'

export default {
    directives: {
        ClickOutside
    },
    components: {
        SignBlock
    },
    data() {
        return {
            env: {
                application_name: process.env.APP_NAME,
                application_url: process.env.APP_URL
            }
        }
    }
}
</script>
