<template lang="html">
    <div
        v-click-outside="outsideCloseMenu"
        class="signin"
        :class="{ 'signin__active' : menuState }"
        @click="triggerMenu({ name: 'client-menu' })"
    >
        <avatar
            class="signin__user-avatar"
            :avatar-fallback="getterPersonalInformation.avatar_fallback"
            :avatar-url="getterPersonalInformation.avatar_url"
            :firstname="getterPersonalInformation.firstname"
            :lastname="getterPersonalInformation.lastname"
            :email="getterPersonalInformation.email"
        />
        <BubbleAttention v-if="getterHeaderMenuAttention" />
        <div :class="{'signin__tab-open-on-hover' : menuState}">
            <div class="profile-dropdown">
                <nav class="profile-dropdown__nav">
                    <div class="profile-dropdown__list">
                        <router-link
                            v-for="item in showingItems"
                            :key="item.name"
                            :to="{ name: item.name, params: item.params }"
                            class="profile-dropdown__list-item"
                        >
                            <span :class="`profile-dropdown__icon profile-dropdown__icon-${item.name}`" />
                            <span :class="`profile-dropdown__list-item__text profile-dropdown__list-item__text_${item.name}`">
                                {{ item.text }}
                                <template v-for="bubble in bubblesAttention">
                                    <BubbleAttention
                                        v-if="item.name === bubble.name && bubble.isShow"
                                        :key="bubble.name"
                                    />
                                </template>
                            </span>
                        </router-link>

                        <a
                            href="#"
                            class="profile-dropdown__list-item logout-link"
                            @click.prevent="logout()"
                        >
                            <span class="profile-dropdown__icon profile-dropdown__icon-logout" />
                            <span class="profile-dropdown__list-item__text profile-dropdown__list-item__text_out">
                                Log out
                            </span>
                        </a>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import Avatar from '@/components/common/Avatar.vue'
import filtersMixin from '@/mixins/filtersMixin.js'
import overlayMixin from '@/mixins/overlayMixin'
import ClickOutside from 'vue-click-outside'
import { mapGetters, createNamespacedHelpers } from 'vuex'
import {
    ACTION_LOGOUT_WRITER,
    ACTION_RESET_ALL_MODULES
} from '@/store/modules/writer/action-types'
import BubbleAttention from '@/components/BubbleAttention.vue'

const {
    mapActions: mapWriterActions
} = createNamespacedHelpers('writer')
export default {
    items: [
        {
            name: 'account',
            text: 'Dashboard',
            params: {},
            needAuth: true
        },
        {
            name: 'orders',
            text: 'Projects',
            params: { page: 1 },
            needAuth: true
        },
        {
            name: 'tickets',
            text: 'Help Center',
            params: { page: 1 }
        },
        {
            name: 'earnings',
            text: 'Earnings',
            params: { page: 1 },
            needAuth: true
        },
        {
            name: 'payouts',
            text: 'Payout History',
            params: { page: 1 },
            needAuth: true
        },
        {
            name: 'profile',
            text: 'Account Settings',
            params: {}
        },
        {
            name: 'documents',
            text: 'Documents',
            params: { page: 1 },
            needAuth: true
        },
        {
            name: 'clients-feedbacks',
            text: 'Reviews',
            params: { page: 1 },
            needAuth: true
        }
    ],
    components: {
        Avatar,
        BubbleAttention
    },
    directives: {
        ClickOutside
    },
    mixins: [
        filtersMixin,
        overlayMixin
    ],
    computed: {
        // TODO: create function to check current window width and send prop to avatar component
        ...mapGetters('writer', [
            'getterPersonalInformation',
            'getterWriterStatuses',
            'getterSettingsAttention',
            'getterDocumentsAttention',
            'getterHeaderMenuAttention'
        ]),
        showingItems() {
            return this.getterWriterStatuses.accessstatus === 'APPLICANT' ? this.$options.items.filter((item) => !item.needAuth) : this.$options.items
        },
        bubblesAttention() {
            return [
                {
                    name: 'profile',
                    isShow: this.getterSettingsAttention
                },
                {
                    name: 'documents',
                    isShow: this.getterDocumentsAttention
                }
            ]
        }
    },
    methods: {
        ...mapWriterActions([
            ACTION_LOGOUT_WRITER,
            ACTION_RESET_ALL_MODULES
        ]),
        outsideCloseMenu() {
            this.closeMenu({ overlayAvailability: true, lockAvailability: true })
        },
        async logout() {
            try {
                await this[ACTION_LOGOUT_WRITER]()
                this.$router.push({ name: 'login' })
            } catch (e) {
                console.error(e)
            }
        }

    }
}
</script>
<style lang="scss">
.signin {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    cursor: pointer;
    position: relative;
    transition: 0.2s;
    >.bubble--attention {
        position: absolute;
        top: -10px;
        right: 8px;
    }

    &__user-avatar {
        display: flex;
        align-items: center;
        &::after {
            content: "";
            display: block;
            min-width: 20px;
            width: 20px;
            height: 10px;
            mask-image: url("~@/assets/img/svg/next.svg");
            mask-size: contain;
            mask-position: center;
            mask-repeat: no-repeat;
            background: $main-color;
            transform: rotate(90deg);
            margin-left: 5px;
        }
    }

    &__active {
        z-index: 999;
        transition: 0.2s;

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: -1px;
            left: 0;
            background: #fff;
        }

        .signin__user-avatar::after {
            transform: rotate(270deg);
        }
    }

    &__tab-open-on-hover {
        z-index: 999;
        transition: 0.2s;

        .profile-dropdown {
            display: block;
            transition: 0.2s;

            &__nav {
                overflow-y: auto;
                overflow-x: hidden;
                height: auto;
            }
        }

        .signin__user-name:after {
            transform: translateY(-3px) rotateX(180deg);
        }
    }

    &__user-mobile {
        display: none;
    }

    &__user-info {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        letter-spacing: normal;
        @media screen and (max-width: 1600px) {
            display: none;
        }
    }

    &__user-email {
        font-weight: 500;
        color: #7c8a9a;
        margin-bottom: 6px;
    }

    &__user-name {
        font-weight: bold;
        text-transform: uppercase;
        color: $font-color-main;

        &:after {
            display: inline-block;
            content: "";
            width: 0px;
            height: 0px;
            margin: 0 0 0 5px;
            border-top: 5px solid $font-color-main;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid transparent;
            transform: translateY(3px);
            transition: 0.2s;
        }

        &__opened {
            &:after {
                transform: translateY(-3px) rotateX(180deg);
                transition: 0.2s;
            }
        }
    }
}

@media (max-width: 480px) {
    .signin {
        &__user-avatar {
            &::after {
                display: none;
            }
        }
    }
}
</style>
