export default {
    data: () => ({
        menuState: false,
        scrollTop: null
    }),
    methods: {
        triggerMenu(state) {
            if (!state) return
            this.menuState
                ? this.closeMenu(state)
                : this.openMenu(state)
        },
        openMenu(state) {
            const vm = this
            if (this.menuState === true) return
            this.menuState = true

            setTimeout(() => {
                vm.changeOverlayState(state)
            }, 50);
        },
        closeMenu(state) {
            if (!state) return
            if (this.menuState === false) return
            this.menuState = false

            this.changeOverlayState(state)
        },
        changeOverlayState(state) {
            if (state.overlayAvailability) {
                this.menuState ? this.showOverlay(state) : this.hideOverlay(state)
            }
            if (state.lockAvailability) {
                this.menuState ? this.lockBackground(state) : this.unlockBackground(state)
            }
        },
        showOverlay() {
            document.getElementById('overlay').classList.remove('eister-modal__overlay-disabled')
            document.getElementById('overlay').classList.add('eister-modal__overlay-active')
        },
        hideOverlay() {
            document.getElementById('overlay').classList.remove('eister-modal__overlay-active')
            document.getElementById('overlay').classList.add('eister-modal__overlay-disabled')
        },
        lockBackground() {
            const body = document.getElementsByTagName('body')[0]
            this.scrollTop = window.pageYOffset
            Object.assign(body.style, {
                position: 'fixed',
                top: `${-this.scrollTop}px`,
                width: `${document.body.clientWidth}px`
            })
        },
        unlockBackground() {
            const body = document.getElementsByTagName('body')[0]
            Object.assign(body.style, {
                position: 'inherit',
                width: 'inherit'
            })
            window.scrollTo(0, this.scrollTop)
        }
    }
}
