<template>
    <div class="bubble--attention">
        !
        <div
            v-if="message"
            class="bubble--attention__tooltip"
        >
            {{ message }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            required: false
        }
    }
}
</script>

<style lang="scss">
.bubble--attention {
    position: relative;
    min-width: 18px;
    width: 18px;
    height: 18px;
    background: $error-color;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 0 7px;
    font-size: 12px;
    font-weight: 100;
    cursor: pointer;
    &__tooltip {
        position: absolute;
        right: 0;
        bottom: calc( 100% + 5px);
        background: rgba(0, 0, 0, 0.45);
        border-radius: 6px;
        width: 300px;
        padding: 10px 15px;
        font-size: 14px;
        opacity: 0;
        transition: opacity 0.3s;
    }
    &:hover {
        .bubble--attention__tooltip {
            opacity: 1;
        }
    }
}
</style>
