<template>
    <svg
        width="36"
        height="36"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="20"
            cy="30"
            r="2"
            stroke="#1161E0"
            stroke-width="1.8"
        />
        <path
            d="M12 18C12 14.8333 13 8.5 20.5 8.5C26 8.5 28 14 28 18"
            stroke="#1161E0"
            stroke-width="1.8"
        />
        <path
            d="M9 22.3721C9 19.9575 10.9575 18 13.3721 18V18C13.7189 18 14 18.2811 14 18.6279V25.9359C14 25.9713 13.9713 26 13.9359 26H11.3245C10.0407 26 9 24.9593 9 23.6755V22.3721Z"
            stroke="#1161E0"
            stroke-width="1.8"
        />
        <path
            d="M31 22.3721C31 19.9575 29.0425 18 26.6279 18V18C26.2811 18 26 18.2811 26 18.6279V25.9359C26 25.9713 26.0287 26 26.0641 26H28.6755C29.9593 26 31 24.9593 31 23.6755V22.3721Z"
            stroke="#1161E0"
            stroke-width="1.8"
        />
        <path
            d="M28 26C28 28.5 25.5 31 22.5 30.5"
            stroke="#1161E0"
            stroke-width="1.8"
        />
    </svg>
</template>

<script>
export default {
    name: 'SystemNotification'
}
</script>
<style scoped lang='scss'>
svg path {
    stroke: $main-color;
}
</style>
