<template>
    <div
        v-if="!auth"
        class="sign-block"
    >
        <router-link
            v-if="!getLoginRoute"
            to="/login"
            class="sign-block-login"
        >
            <span class="sign-block-login-icon" />
            <span class="sign-block-login-text">Account</span>
        </router-link>
    </div>
    <div
        v-else-if="auth && !getLoginRoute"
        class="sign-block"
        :class="{'sign-block__auth' : auth}"
    >
        <!--        <bookmarks-hint v-if="getterWriterStatuses.trust_status === 'TRUSTED'" />-->
        <alert-block />
        <control-panel-menu />
    </div>
</template>

<script>
// components
import ControlPanelMenu from '@/components/header/ControlPanelMenu'
// import BookmarksHint from '@/components/header/BookmarksHint'
import AlertBlock from '@/components/header/Alert/AlertBlock'
// vuex
import { mapState, mapGetters } from 'vuex'

export default {
    components: {
        ControlPanelMenu,
        AlertBlock
        // BookmarksHint
    },
    computed: {
        ...mapState('writer', {
            auth: 'auth'
        }),
        ...mapGetters('writer', [
            'getterWriterStatuses'
        ]),
        getLoginRoute() {
            return this.$route.path === '/login'
        }
    }
}
</script>

<style lang="scss" scoped>
.sign-block {
    display: flex;
    align-items: center;
    height: 100%;
    width: fit-content;
    margin-left: 30px;

    &-login {
        display: flex;
        color: #ff690c;
        font-weight: 600;
        align-items: center;
        &:hover {
            color: #ffa600;
            &-icon {
                background: #ffa600;
            }
        }

        &-icon {
            display: block;
            background-color: $main-color;
            transition: all 0.3s ease-in-out;
            mask-image: url("~@/assets/img/svg/user_account.svg");
            mask-size: contain;
            mask-position: center;
            mask-repeat: no-repeat;
            margin-right: 10px;
            min-width: 20px;
            height: 22px;

            img {
                filter: $filter-color;
            }
        }

        &-text {
            color: $font-color-main;
            font-weight: 500;
            font-size: 16px;
        }
    }
    .bookmarks-hint {
        margin-right: 20px;
    }
    .alert {
        margin-right: 20px;
    }
}

@media (max-width: 1200px) {
    .sign-block-login {
        &-text {
            display: none;
        }

        &-icon {
            margin-right: 0;
        }
    }
    .profile-dropdown {
        top: 60px;
        right: -80px;
        left: auto;
    }
}

@media (max-width: 480px) {
    .sign-block {
        .bookmarks-hint {
            margin-right: 25px;
        }
    }
}
</style>
