// import Cow from 'mdi-vue/Cow'
// import CreditCard from 'mdi-vue/CreditCard'

const TYPES = {
    success: 'success',
    primary: 'primary',
    warning: 'warning',
    danger: 'danger'
}

export const HeaderInfoList = [
    {
        type: TYPES.success,
        customIcon: '',
        text: 'All writers are required to take the English exam (fresh session) and reupload current KYC documents (passport, utility bill, etc.). We will give priority to the writers who have complied with this instruction while allocating assignments.',
        id: '11'
    }
]
