<template>
    <footer
        id="footer"
        class="footer"
    >
        <div class="footer-wrapper wrapper">
            <div>
                <h5 class="footer-title">
                    Need help?
                </h5>
                <p class="footer-desc">
                    Our support agents are here to assist you!
                </p>

                <custom-button
                    v-if="writer.auth"
                    class="btn-base btn-main"
                    @on-btn-click="$router.push({ name: 'support_form'})"
                >
                    Contact Us
                </custom-button>
            </div>
            <div class="footer-links">
                <p class="footer-links__title">
                    Resources:
                </p>
                <a
                    href="/cp/documents/privacy"
                    class="footer-link"
                >
                    Privacy Policy
                </a>
                <a
                    href="/cp/documents/terms"
                    class="footer-link"
                >
                    Terms of service
                </a>
            </div>
        </div>
        <div class="footer-copyright wrapper">
            <a href="/">
                © 2020 Freelancewriting.center
            </a>
        </div>
    </footer>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            api: process.env.VUE_APP_DOMAIN
        }
    },
    computed: {
        ...mapGetters('writer', {
            writer: 'getterMainWriterInfo'
        })
    }
}
</script>
