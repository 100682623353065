<template>
    <loading-overlay v-if="loading" />
    <div v-else>
        <slot name="header">
            <header-top-info />
            <Header />
        </slot>
        <div :class="{'background-wrapper': bgWrapper}">
            <div :class="classes">
                <slot name="content" />
            </div>
        </div>
        <slot name="footer">
            <Footer />
        </slot>
    </div>
</template>

<script>
import Header from '@/components/header/Header';
import Footer from '@/components/Footer';
// import LoadingOverlay from '@/components/LoadingOverlay';
import HeaderTopInfo from '@/components/HeaderTopInfo';

export default {
    name: 'MainLayout',
    components: {
        // LoadingOverlay,
        Header,
        Footer,
        HeaderTopInfo
    },
    props: {
        classes: String,
        bgWrapper: Boolean,
        loading: Boolean,
        hidereport: Boolean,
        total: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style scoped lang="scss">
.wrapper {
    margin-bottom: 30px;
    .wrapper {
        margin-bottom: 0;
    }
}
.mt-0 {
    margin-top: 0 !important;
}
</style>
