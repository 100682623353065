<template>
    <div class="header-top-bar">
        <transition-group
            name="fadeHeight"
            mode="out-in"
        >
            <template v-if="showList">
                <div
                    v-for="item in list"
                    :key="item.id"
                    class="header-top-bar__row"
                    :class="`header-top-bar__row--${item.type}`"
                >
                    <div class="wrapper">
                        <div
                            class="header-top-bar__row_content"
                        >
                            <component
                                :is="item.customIcon ? item.customIcon : item.type"
                                class="header-top-bar__flag"
                            />

                            <div v-html="item.text" />

                            <span
                                class="header-top-bar__close"
                                @click="closeInfoItem(item.id)"
                            >
                                <Close />
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </transition-group>
    </div>
</template>

<script>
import Close from 'mdi-vue/Close.vue'
import InformationOutline from 'mdi-vue/InformationOutline'
import CheckOutline from 'mdi-vue/CheckOutline'
import AlertBoxOutline from 'mdi-vue/AlertBoxOutline'
import CloseOctagon from 'mdi-vue/CloseOctagon'

import { HeaderInfoList } from '@/helpers/HeaderTopInfo'

export default {
    components: {
        Close,
        primary: InformationOutline,
        success: CheckOutline,
        warning: AlertBoxOutline,
        danger: CloseOctagon
    },
    data() {
        return {
            list: [],
            showList: false
        }
    },
    computed: {
        fullList() {
            return HeaderInfoList
        },
        closedInfoItemFromStorage() {
            const storage = localStorage.getItem('closedInfoItem')
            if (!storage) {
                localStorage.setItem('closedInfoItem', '[]')
            }
            return JSON.parse(localStorage.getItem('closedInfoItem'));
        }
    },
    created() {
        this.list = this.fullList.reduce((acc, curr) => {
            if (!this.closedInfoItemFromStorage.includes(curr.id)) {
                acc.push(curr)
            }
            return acc
        }, [])
        setTimeout(() => {
            this.showList = true
        }, 1000);
    },
    methods: {
        closeInfoItem(id) {
            const newList = this.closedInfoItemFromStorage
            newList.push(id)
            this.list = this.list.filter((item) => item.id !== id)
            localStorage.setItem('closedInfoItem', JSON.stringify(newList))
        }
    }
}
</script>

<style lang="scss">
    .header-top-bar{
        &__row{
            display: flex;
            align-items: center;
            &_content{
                display: flex;
                align-items: center;
                padding: 20px 0;
                padding-right: 40px;
                position: relative;
                font-size: 15px;
                line-height: 1.4;
            }
            &--primary {
                background: rgb(42, 53, 71);
                color: #fff;
            }
            &--success {
                background-color: #d1e7dd;
                color: #0f5132;
            }
            &--warning {
                color: #664d03;
                background-color: #fff3cd;
            }
            &--danger {
                color: #842029;
                background-color: #f8d7da;
            }
        }
        &__flag{
            margin-right: 20px;
        }
        &__close{
            cursor: pointer;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            svg {
                fill: grey;
                transition: .3s all;
            }
            &:hover {
                svg {
                    fill: #fff;
                }
            }
        }
    }
    .fadeHeight-enter-active,
    .fadeHeight-leave-active {
        transition: all 0.2s;
        max-height: 230px;
    }
    .fadeHeight-enter,
    .fadeHeight-leave-to
    {
        opacity: 0;
        max-height: 0px;
    }
</style>
