<template>
    <div
        class="alert_block"
        @click="showPage"
    >
        <div class="alert_block_header">
            <div class="alert_block_header_icon">
                <Avatar
                    v-if="avatarUrl"
                    :avatar-url="avatarUrl"
                    class="alert_block_header_avatar"
                />
                <component
                    :is="avatarComponent"
                    v-else
                />
                <i
                    v-if="isUnread"
                    class="circle_unread"
                />
            </div>
            <div class="alert_block_header_info">
                <p class="alert_block_title">
                    {{ item.data.data.subject }}
                </p>
                <p class="alert_block_date">
                    <span>{{ item.created_at | moment_from }} ago</span>
                    <span>
                        {{ item.data.data.ticketid }}
                    </span>
                    <span>
                        {{ item.data.data.orderid }}
                    </span>
                </p>
            </div>
        </div>
        <div
            v-if="item.type"
            class="alert_block_body"
            v-html="item.data.data.message"
        />
    </div>
</template>

<script>
import NewMessage from '@/components/icons/Alert/NewMessage.vue';
import NewFile from '@/components/icons/Alert/NewFile.vue';
import NewRevision from '@/components/icons/Alert/NewRevision.vue';
import SystemNotification from '@/components/icons/Alert/SystemNotification.vue';
import NewTicketReply from '@/components/icons/Alert/NewTicketReply.vue';

import Avatar from '@/components/common/Avatar.vue';

import filtersMixin from '@/mixins/filtersMixin'

export default {
    name: 'AlertItem',
    components: {
        NewMessage,
        NewFile,
        NewRevision,
        SystemNotification,
        Avatar,
        NewTicketReply
    },
    mixins: [
        filtersMixin
    ],
    props: {
        item: {
            type: Object,
            required: true
        },
        isUnread: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        avatarComponent() {
            return this.$options.components[this.item.type] ?? 'SystemNotification'
        },
        avatarUrl() {
            return this.item.data.user?.avatar_url ?? false
        }
    },
    methods: {
        showPage() {
            window.location.href = this.item.data.data.url
        }
    }
}
</script>
